<template>
  <div>
    <section class="page-banner">
      <div class="banner-inner">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <h1>Faaliyet Raporları</h1>
          </div>
        </div>
      </div>
    </section>

    <div class="sidebar-page-container container-fluid">
      <div class="auto-container">
        <div class="row clearfix">
          <!--Content Side-->

          <div class="content-side col-lg-8 col-md-12 col-sm-12">
            <div class="content-inner container">
              <div class="events-list container">
                <!--Event Block-->

                <div
                  v-for="(rapor, index) in Raporlar"
                  :key="index"
                  class="event-block"
                >
                  <div class="inner-box">
                    <div class="content-box">
                      <div class="date-box">
                        <div class="date">
                          <span class="day">14</span>
                          <span class="month">Aralık</span>
                        </div>
                      </div>

                      <div class="content">
                        <div class="cat-info">
                          <a :href="rapor.Dosyalar[0].dosyaurl" target="blank"
                            >Faaliyet Raporu</a
                          >
                        </div>

                        <h3>
                          <a
                            :href="rapor.Dosyalar[0].dosyaurl"
                            target="blank"
                            >{{ rapor.raporAdi }}</a
                          >
                        </h3>

                        <div class="location">
                          {{ Belediyebilgiler.belediyeadi }} Belediyesi
                        </div>

                        <div class="read-more">
                          <a :href="rapor.Dosyalar[0].dosyaurl" target="blank"
                            >Görüntüle</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--<div style="text-align: center;">
                <button  class="loadclass">Daha fazla</button>
              </div>-->
            </div>
          </div>

          <!--Sidebar Side-->
          <Sidebar />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/views/Sidebar";
export default {
  name: "FaaliyetRaporlari",
  data() {
    return {
      loadkont: false,
      Raporlar: [],
      Belediyebilgiler: {},
    };
  },
  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Genel");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
    await this.$store.dispatch("vericekme", "Raporlar");
    const rapormap = this.$store.getters.getRaporlar;
    this.Raporlar = Object.values(rapormap);
    this.Raporlar.sort(function (b, a) {
      return a.raporId - b.raporId;
    });
    this.loadkontrol = true;
  },
  components: {
    Sidebar,
  },
};
</script>
<style>
.loadclass {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-top: 20px;
  min-width: 180px;
  text-transform: capitalize;
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 700;
  padding: 18px 30px 12px;
  background: var(--anacolor);
  border-radius: 0px;
  border-radius: 0px;
  overflow: hidden;
  font-family: "Ubuntu", sans-serif;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  align-items: center;
}
.loadclass:hover {
  background: #222222;
  color: #ffffff;
}
</style>