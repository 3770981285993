<template>
  <div>
    <div v-if="!loadkont" class="preloader">
      <div class="icon"></div>
    </div>
    <section class="page-banner">
      <div class="banner-inner">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <h1>Duyuru İlan Detay</h1>
          </div>
        </div>
      </div>
    </section>
    <div class="sidebar-page-container container-fluid">
      <div class="auto-container">
        <div class="row clearfix">
          <!--Content Side-->

          <div class="content-side col-lg-8 col-md-12 col-sm-12">
            <div class="content-inner">
              <div class="single-post container">
                <div class="post-details container">
                  <div class="main-image-box">
                    <figure class="image">
                      <img :src="DuyuruIlanBilgisi.kapak" alt />
                    </figure>
                  </div>

                  <h2>{{ DuyuruIlanBilgisi.duyruilanAdi }}</h2>
                  <h5 style="margin-top: -10px">
                    {{ DuyuruIlanBilgisi.duyruilanTarihi }}
                  </h5>

                  <p
                    v-html="DuyuruIlanBilgisi.duyruilanAciklamasi"
                    style="white-space: pre-line"
                  ></p>

                  <div class="mixit-gallery filter-gallery">
                    <h3 style="color: black">Duyuru İlan Fotoğrafları</h3>
                    <div
                      v-if="DuyuruIlanBilgisi.Dosyalar.length > 0"
                      class="filter-gallery-one row clearfix"
                      id="MixItUp7C2D6E"
                    >
                      <!--Gallery Item-->

                      <div
                        v-for="(resim, index) in DuyuruIlanBilgisi.Dosyalar"
                        :key="index"
                        class="
                          gallery-block
                          mix
                          all
                          tour
                          industry
                          col-lg-4 col-md-4 col-sm-12
                        "
                        style="display: inline-block"
                      >
                        <div
                          class="inner-box wow fadeInUp animated"
                          data-wow-delay="0ms"
                          data-wow-duration="1500ms"
                          style="
                            visibility: visible;
                            animation-duration: 1500ms;
                            animation-delay: 0ms;
                            animation-name: fadeInUp;
                          "
                        >
                          <div class="image-box" style="height=100px">
                            <figure class="image">
                              <img :src="resim.dosyaurl" alt />
                            </figure>

                            <div class="zoom-btn">
                              <a
                                class="lightbox-image zoom-link"
                                :href="resim.dosyaurl"
                                data-fancybox="gallery"
                              >
                                <span class="icon flaticon-zoom-in"></span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!--<div class="load-more text-center">
                    
                                        <a href="portfolio-grid-2.html" class="theme-btn btn-style-one"><span class="btn-title">Load More</span></a>
                    
                    </div>-->
                  </div>
                </div>

                <Sosyal />
              </div>
            </div>
          </div>
          <Sidebar />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/views/Sidebar.vue";
import Sosyal from "@/views/Sosyalbanner";
export default {
  name: "duyuruilandetay",
  data() {
    return {
      DuyuruIlanBilgisi: {},
      Resimler: {},
      loadkont: false,
    };
  },
  components: {
    Sidebar,
    Sosyal,
  },
  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "DuyuruIlanlar");
    await this.duyuruilandetaycek();
    this.scripts();
    this.loadkont = true;
  },
  methods: {
    scripts() {
      let customscript2 = document.createElement("script");
      customscript2.setAttribute("src", "/js/mixitup.js");
      document.head.appendChild(customscript2);
      let customscript3 = document.createElement("script");
      customscript3.setAttribute("src", "/js/mixitup-loadmore.js");
      document.head.appendChild(customscript3);
      let customscript = document.createElement("script");
      customscript.setAttribute("src", "/js/custom-script.js");
      document.head.appendChild(customscript);
    },
    async duyuruilandetaycek() {
      const ID = this.$route.params.duyuruilanId;
      const dilanlar = this.$store.getters.getDuyuruIlanlar;
      this.DuyuruIlanBilgisi = dilanlar[ID];
    },
  },
};
</script>