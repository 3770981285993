<template>
  <div id="home">
    <!--sidebar menu start-->
    <!-- <div class="sidebar-menu" id="sidebar-menu">
      <button class="sidebar-menu-close"><i class="flaticon-close"></i></button>
      <div class="sidebar-inner">
        <div class="sidebar-logo">
          <img src="assets/img/logo.png" alt="logo" />
        </div>
        <div class="sidemenu-text">
          <p>
            We believe brand interaction is key in commu- nication. Real inno
            vations and a positive customer experience are the heart of
            successful commu- nication.
          </p>
        </div>
        <div class="sidebar-contact">
          <h4>Contact Us</h4>
          <ul>
            <li><i class="fa fa-map-marker"></i>Lavaca Street, Suite 2000</li>
            <li><i class="fa fa-envelope"></i>email@evha.com</li>
            <li><i class="fa fa-phone"></i>(+880) 172570051</li>
          </ul>
        </div>
        <div class="sidebar-subscribe">
          <input type="text" placeholder="Email" />
          <button><i class="fa fa-long-arrow-right"></i></button>
        </div>
        <div class="social-link">
          <ul>
            <li>
              <a href="#eee"><i class="fa fa-facebook-f"></i></a>
            </li>
            <li>
              <a href="#"><i class="fa fa-twitter"></i></a>
            </li>
            <li>
              <a href="#"><i class="fa fa-instagram"></i></a>
            </li>
            <li>
              <a href="#"><i class="fa fa-pinterest"></i></a>
            </li>
          </ul>
        </div>
      </div>
    </div> -->
    <!--sidebar menu end-->
    <!-- banner start -->
    <div class="banner-style-2">
      <div class="banner-slider-2">
        <div
          class="banner-single-style-2"
          v-for="(haber, index) in Haberler.slice(0, 1)"
          :key="index"
        >
          <h2>
            {{ haber.haberAdi.slice(0, 40) }}
            <span v-if="haber.haberAdi.length > 60">...</span>
          </h2>
          <p v-html="haber.haberAciklamasi.slice(0, 240) + '...'"></p>
          <div class="d-flex justify-content-start">
            <div class="btn-wrapper mr-3" data-animation-in="fadeInUp">
              <h5
                @click="detayagit(haber.haberId)"
                class="btn btn-custom-default"
              >
                Görüntüle
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div class="banner-left">
        <ul class="banner-two-social">
          <li>
            <a :href="this.Belediyebilgiler.face" target="_blank"
              ><i class="fa fa-facebook-f"></i
            ></a>
          </li>
          <li>
            <a :href="this.Belediyebilgiler.twit" target="_blank"
              ><i class="fa fa-twitter"></i
            ></a>
          </li>
          <li>
            <a :href="this.Belediyebilgiler.insta" target="_blank"
              ><i class="fa fa-instagram"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
    <!-- banner end -->

    <!-- action area start -->
    <div
      class="action-area padding-top-120 padding-bottom-120 position-relative"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-5 align-self-center">
            <div class="content-box-style-01">
              <p class="section-subtitle">Tarihçe</p>
              <h2 class="title">{{ this.Belediyebilgiler.belediyeadi }}</h2>
              <p v-html="Ilce.tarihce.slice(0, 637) + '...'" class="para"></p>
              <div class="btn-wrapper align-self-center margin-top-50">
                <router-link class="btn btn-custom-primary" to="/tarihce">
                  Devamını Oku
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-6 offset-lg-1">
            <div class="goal-area">
              <ul class="goal-list">
                <li>
                  <div class="icon"><i class="flaticon-financial"></i></div>
                  <div class="content">
                    <h4 class="title">
                      <a :href="Belediyebilgiler.ebelediye"> E-Belediye</a>
                    </h4>
                  </div>
                </li>
                <li>
                  <div class="icon"><i class="flaticon-financial"></i></div>
                  <div class="content">
                    <h4 class="title">
                      <a :href="Belediyebilgiler.nobetciecz"
                        >Nöbetçi Eczaneler</a
                      >
                    </h4>
                  </div>
                </li>
                <li>
                  <div class="icon"><i class="flaticon-financial"></i></div>
                  <div class="content">
                    <h4 class="title">
                      <router-link to="/belgeler">
                        Belge Örnekleri
                      </router-link>
                    </h4>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- action area end  -->

    <!-- news area Start -->
    <!-- <div class="home-news-area padding-top-115">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="text-center padding-bottom-40">
              <h2 class="section-title">Haberlerimiz</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="swiper-slider swiper-container two">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide image-overlay"
                v-for="(haber, index) in Haberler.slice(0, 8)"
                :key="index"
              >
                <div class="news-item-style-03">
                  <div class="thumb">
                    <img :src="haber.kapak" alt="" />
                  </div>
                  <div class="content">
                    <p class="subtitle">Güncel Haberler</p>
                    <h4 class="title">
                      <a href="blog-details.html">{{ haber.haberAdi }} </a>
                    </h4>
                    <ul class="blog-meta d-flex flex-column flex-lg-row">
                      <li>
                        <a>{{ haber.haberTarihi }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-button-next">
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </div>
            <div class="swiper-button-prev">
              <i class="fa fa-angle-left" aria-hidden="true"></i>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
      </div>
    </div>
     -->
    <!-- news area End -->

    <!-- candidate area start -->
    <div class="candidate-area2 padding-bottom-25 position-relative mt-5">
      <div class="container-fluid">
        <div class="row candidate-slider2">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-6">
                <div class="content-box-style-05 row">
                  <h2 class="title">Başkan</h2>
                  <p
                    class="description"
                    v-html="this.Baskanbilgisi.mesaj.slice(0, 300) + '...'"
                  ></p>
                  <ul class="mb-5">
                    <li>
                      <a
                        ><i class="fa fa-phone"></i
                        >{{ this.Belediyebilgiler.iletisim }}</a
                      >
                    </li>
                    <li>
                      <a
                        ><i class="fa fa-envelope"></i
                        >{{ this.Belediyebilgiler.mail }}</a
                      >
                    </li>
                    <li>
                      <a
                        ><i class="fa fa-map-marker"></i>
                        {{ this.Belediyebilgiler.belediyeadresi }}
                      </a>
                    </li>
                  </ul>
                  <span class="name">{{ this.Baskanbilgisi.baskanadi }}</span>
                  <span class="address">{{
                    this.Baskanbilgisi.baskanslogan
                  }}</span>
                  <div
                    class="
                      btn btn-custom-primary
                      mt-5
                      d-flex
                      justify-content-center
                      w-50
                    "
                  >
                    <router-link to="/iletisim" tag="a" class="text-center"
                      >Başkan'a Sor</router-link
                    >
                  </div>
                </div>
              </div>
              <div class="col-lg-6 align-self-center">
                <div class="image-box-style-02 row">
                  <img :src="this.Baskanbilgisi.kapak" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- candidate area end -->

    <!-- team area start  -->
    <div class="guest-area padding-top-115 padding-bottom-115">
      <div class="container" v-if="Ilanlar.length > 0">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center margin-bottom-70">
              <h2 class="section-title">Duyurular & İlanlar</h2>
            </div>
          </div>
        </div>
        <div class="row about-member-count">
          <div
            class="col-lg-3 col-sm-6"
            v-for="(ilan, index) in Ilanlar.slice(0, 4)"
            :key="index"
          >
            <div
              class="icon-box-style-03 text-center margin-bottom-30"
              :class="index % 2 === 0 && 'down'"
            >
              <div class="sb-content">
                <div class="d-flex justify-content-center align-items-center">
                  <img
                    src="assets/img/bullhorn.svg"
                    alt=""
                    style="margin-right: 10px; margin-bottom: 10px"
                  />
                  <p class="subtitle" style="font-size: 18px">
                    {{ ilan.duyruilanTarihi.split(" ")[0] }}
                    {{ ilan.duyruilanTarihi.split(" ")[1] }}
                  </p>
                </div>
                <h4 class="title">
                  {{ ilan.duyruilanAdi }}
                </h4>
                <p v-html="ilan.duyruilanAciklamasi.slice(0, 100)"></p>
              </div>
              <div class="btn-wrapper text-center margin-top-30">
                <a @click="duyurudetayagit(ilan.duyruilanId)">Duyuruyu Gör</a>
                ➝
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- team area end  -->

    <!-- stories area start -->
    <div class="stories-area">
      <div class="container">
        <div class="row">
          <div class="col align-self-center">
            <div class="content-box-style-06">
              <h2 class="title">Projelerimiz</h2>
              <div class="row margin-top-50">
                <div class="col-md-3 col-md-12 col-sm-12">
                  <ul class="nav nav-tabs">
                    <li>
                      <a
                        class="active"
                        data-toggle="tab"
                        @click="refreshproject(0)"
                        >Tüm Projeler</a
                      >
                    </li>
                    <li>
                      <a data-toggle="tab" @click="refreshproject(1)"
                        >Planlanan</a
                      >
                    </li>
                    <li>
                      <a data-toggle="tab" @click="refreshproject(2)"
                        >Devam Ediyor</a
                      >
                    </li>
                    <li>
                      <a data-toggle="tab" @click="refreshproject(3)"
                        >Tamamlandı</a
                      >
                    </li>
                  </ul>
                </div>
                <div class="col-md-9">
                  <Project :projects="this.myArray.slice(0, 3)" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Project from "../components/Project.vue";

export default {
  name: "Home",
  data() {
    return {
      urll: "",
      Haberler: [],
      Slides: {},
      Ilanlar: [],
      Kararlar: [],
      Yerler: [],
      Baskanbilgisi: {},
      Projeler: [],
      YeniProjeler: [],
      loadkont: false,
      kont: false,
      domAD: "",
      Belediyebilgiler: {},
      myArray: [],
      Ilce: {},
      dataReady: true,
    };
  },
  components: {
    Project,
  },

  // linear-gradient(0deg, rgba(255, 255, 255, 0.9),rgba(255, 255, 255, 0.9)),
  //background-repeat: round; background-attachment: fixed;
  async created() {
    const domainAd = window.location.hostname;
    this.domAD = domainAd;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.belediyecek();
    await this.haberlercek();
    await this.$store.dispatch("vericekme", "Genel");
    await this.$store.dispatch("vericekme", "Slides");
    await this.$store.dispatch("vericekme", "Ilce");
    this.Slides = this.$store.getters.getSlides;
    //console.log(this.Slides);
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
    this.urll = `url(${this.Belediyebilgiler.kapak})`;
    this.Ilce = this.$store.getters.getIlce;
    // console.log(this.Belediyebilgiler);
    this.loadkont = true;
    await this.baskancek();
    await this.duyuruilancek();
    await this.kararlarcek();
    await this.yerlercek();
    await this.projelercek();

    this.scriptekleslide();
    this.myArray = this.Projeler;
  },
  methods: {
    detayagit(ID) {
      this.$router.push("/haberdetay/" + ID);
    },
    projedetayagit(ID) {
      this.$router.push("/projedetay/" + ID);
    },
    yerdetayagit(ID) {
      this.$router.push("/kentrehberidetay/" + ID);
    },
    duyurudetayagit(ID) {
      this.$router.push("/duyurudetay/" + ID);
    },
    async haberlercek() {
      await this.$store.dispatch("vericekme", "Haberler");
      const habermap = this.$store.getters.getHaberler;
      this.Haberler = Object.values(habermap);
      this.Haberler.sort(function (b, a) {
        return a.haberId - b.haberId;
      });
      this.kont = true;
    },
    async belediyecek() {},
    async scriptekleslide() {
      // SCRİPT
      let customscript = await document.createElement("script");
      customscript.setAttribute("src", "js/custom-script.js");
      document.head.appendChild(customscript);
      // SCRİPT
      let customscript2 = await document.createElement("script");
      customscript2.setAttribute("src", "js/main.js");
      document.head.appendChild(customscript2);
      // SCRİPT
      let customscript3 = await document.createElement("script");
      customscript3.setAttribute("src", "js/plugins.js");
      document.head.appendChild(customscript3);
      // SCRİPT
      let customscript4 = await document.createElement("script");
      customscript4.setAttribute("src", "js/jquery-2.1.1.min.js");
      document.head.appendChild(customscript4);
      // SCRİPT
      let customscript5 = await document.createElement("script");
      customscript5.setAttribute("src", "assets/js/script.js");
      document.head.appendChild(customscript5);
      // SCRİPT
      let customscript6 = await document.createElement("script");
      customscript6.setAttribute("src", "js/owl.js");
      document.head.appendChild(customscript6);
      // SCRİPT
      let customscript7 = await document.createElement("script");
      customscript7.setAttribute("src", "public/css/slick.css");
      document.head.appendChild(customscript7);
      // SCRİPT
      let customscript8 = await document.createElement("script");
      customscript8.setAttribute("src", "public/css/slick-theme.css");
      document.head.appendChild(customscript8);
    },
    async baskancek() {
      await this.$store.dispatch("vericekme", "Baskan");
      this.Baskanbilgisi = this.$store.getters.getBaskan;
      //console.log(this.Baskanbilgisi);
    },
    async duyuruilancek() {
      await this.$store.dispatch("vericekme", "DuyuruIlanlar");
      const dilanlar = this.$store.getters.getDuyuruIlanlar;
      this.Ilanlar = Object.values(dilanlar);
      this.Ilanlar.sort(function (b, a) {
        return a.duyruilanId - b.duyruilanId;
      });
      //console.log(this.Ilanlar);
    },

    async kararlarcek() {
      await this.$store.dispatch("vericekme", "Kararlar");
      const kararlar1 = this.$store.getters.getKararlar;
      this.Kararlar = Object.values(kararlar1);
      this.Kararlar.sort(function (b, a) {
        return a.kararId - b.kararId;
      });
    },
    async yerlercek() {
      await this.$store.dispatch("vericekme", "Yerler");
      const yerler1 = this.$store.getters.getYerler;
      this.Yerler = Object.values(yerler1);
      this.Yerler.sort(function (b, a) {
        return a.yerId - b.yerId;
      });
    },
    async projelercek() {
      await this.$store.dispatch("vericekme", "Projeler");
      const projeler1 = this.$store.getters.getProjeler;
      this.Projeler = Object.values(projeler1);
      this.Projeler.sort(function (b, a) {
        return a.projeId - b.projeId;
      });
    },

    refreshproject(index) {
      this.myArray = [];
      if (index == 0) this.myArray = this.Projeler;
      else
        this.myArray = this.Projeler.filter((item) => item.projeDurum == index);
    },
  },
  computed: {
    teststyle() {
      return {
        background:
          "linear-gradient(0deg, rgba(255, 255, 255, 0.9),rgba(255, 255, 255, 0.9))," +
          this.urll,
        "background-repeat": "round",
        "background-attachment": "fixed",
      };
    },
  },
  mounted() {
    if (window.location.hostname == "kaymakli.zeplinx.com") {
      let twitscrpt = document.createElement("script");
      twitscrpt.setAttribute("src", "https://platform.twitter.com/widgets.js");
      document.head.appendChild(twitscrpt);
    }

    // let customscript = document.createElement("script");
    // customscript.setAttribute("src", "/assets/js/main.js");
    // document.head.appendChild(customscript);
    // let customscript2 = document.createElement("script");
    // customscript2.setAttribute("src", "/assets/js/script.js");
    // document.head.appendChild(customscript2);
  },
};
</script>
<style>
#videoContainment {
  position: fixed;
  width: 100% !important;
  height: 100% !important;
  left: 0;
  top: 0;
  overflow: hidden;
}
</style>
