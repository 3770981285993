<template>
  <div id="app">
    <div class="page-wrapper">
      <div class="preloader2">
        <div class="icon"></div>
      </div>
      <Header />
      <router-view />
      <Footer />
      <div class="scroll-to-top scroll-to-target" data-target="html">
        <span class="icon flaticon-up-arrow-angle"></span>
      </div>
    </div>
    <!-- Counter: {{ information.websiteDailyCounter + 1 }} -->
  </div>
</template>
<script>
import firebase from "firebase/app";
import Header from "@/views/Header.vue";
import Footer from "@/views/Footer.vue";

export default {
  data() {
    return {
      information: {},
      "--accent-color": "#ffffff",
    };
  },
  async created() {
    const test1 = document.documentElement;
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Genel");
    const belediye = this.$store.getters.getBelgenel;
    this.information = this.$store.getters.getBelgenel;
    test1.style.setProperty("--anacolor", belediye.anarenk);
    test1.style.setProperty("--headcolor", belediye.headrenk);
    document.title = belediye.belediyeadi + " Belediyesi Resmi Web Sitesi";
    const icon = document.createElement("link");
    icon.rel = "shortcut icon";
    icon.type = "image/x-icon";
    icon.href = belediye.kapak;
    document.head.appendChild(icon);
    await this.websiteCounter();
  },
  components: {
    Header,
    Footer,
  },
  methods: {
    async websiteCounter() {
      var newCount = this.information.websiteDailyCounter;
      newCount += 1;
      var domainId = this.$store.getters.getDomainId;
      await firebase
        .firestore()
        .collection("Genel")
        .doc(domainId)
        .set(
          {
            Genel: {
              websiteDailyCounter: newCount,
            },
          },
          { merge: true }
        );
    },
  },

  mounted() {
    let customscript = document.createElement("script");
    customscript.setAttribute("src", "js/custom-script.js");
    document.head.appendChild(customscript);
  },
};
</script>
<style scoped>
.header-top-one {
  background: var(--accent-color);
}
</style
>>
