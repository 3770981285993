<template>
  <div>
    <div v-if="!loadkont" class="preloader">
      <div class="icon"></div>
    </div>
    <section class="page-banner">
      <div class="banner-inner">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <h1>Birimler</h1>
          </div>
        </div>
      </div>
    </section>

    <div class="sidebar-page-container container-fluid">
      <div class="auto-container">
        <div class="row clearfix">
          <!--Content Side-->

          <div class="content-side col-lg-8 col-md-12 col-sm-12 text-center">
            <div class="content-inner">
              <div class="single-post container">
                <div class="post-details">
                  <div class="main-image-box">
                    <figure class="image">
                      <img :src="BirimBilgisi.kapak" alt />
                    </figure>
                  </div>
                  <h2>{{ BirimBilgisi.birimadi }}</h2>
                  <p
                    v-html="BirimBilgisi.birimaciklamasi"
                    style="white-space: pre-line"
                  ></p>
                </div>

                <Sosyal />
              </div>
            </div>
          </div>
          <Sidebar />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/views/Sidebar";
import Sosyal from "@/views/Sosyalbanner";
export default {
  name: "Birimdetay",
  data() {
    return {
      BirimBilgisi: {},
      loadkont: false,
    };
  },
  components: {
    Sidebar,
    Sosyal,
  },
  async created() {
    const ID = this.$route.params.birimId;
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Birimler");
    const birimmap = this.$store.getters.getBirimler;
    this.BirimBilgisi = birimmap[ID];
    this.loadkont = true;
  },
};
</script>