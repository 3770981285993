<template>
  <div class="row tab-content">
    <div
      class="col-lg-4 col-md-12 col-sm-12 tab-pane fade in active show"
      v-for="(proje, index) in projects"
      :key="index"
    >
      <div class="icon-box-style-06 text-center">
        <div class="sb-icon">
          <img :src="proje.kapak" alt="" class="sb-icon" />
        </div>
        <div class="sb-content">
          <h4 class="title">{{ proje.projeAdi }}</h4>
          <div class="btn-wrapper">
            <a
              class="btn btn-custom-primary"
              @click="projedetayagit(proje.projeId)"
              >Projeye Git</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Project",
  props: {
    projects: Array,
  },
  methods: {
    projedetayagit(ID) {
      this.$router.push("/projedetay/" + ID);
    },
  },
};
</script>
