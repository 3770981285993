<template>
  <div>
    <!-- footer area start -->
    <footer class="footer-area footer-style-1 main-bg">
      <div class="footer-top">
        <div class="container">
          <div class="row">
            <div class="col-xl-4">
              <div class="footer-widget widget contact-widget">
                <router-link to="/" class="footer-logo">
                  <img
                    :src="Belediyebilgiler.kapak"
                    style="height: 70px"
                    alt="logo"
                  />
                </router-link>

                <h5>{{ Belediyebilgiler.belediyeadi }} Belediyesi</h5>

                <ul class="contact_info_list">
                  <li class="single-info-item">
                    <div class="icon">
                      <i class="fa fa-phone"></i>
                    </div>
                    <div class="details">
                      {{ Belediyebilgiler.iletisim }}
                    </div>
                  </li>
                  <li class="single-info-item">
                    <div class="icon">
                      <i class="fa fa-envelope"></i>
                    </div>
                    <div class="details">
                      {{ Belediyebilgiler.mail }}
                    </div>
                  </li>
                  <li class="single-info-item">
                    <div class="icon">
                      <i class="fa fa-map-marker"></i>
                    </div>
                    <div class="details">
                      {{ Belediyebilgiler.belediyeadresi }}
                    </div>
                  </li>
                </ul>
                <ul class="footer-social">
                  <li>
                    <a :href="this.Belediyebilgiler.face" target="_blank"
                      ><i class="fa fa-facebook-f"></i
                    ></a>
                  </li>
                  <li>
                    <a :href="this.Belediyebilgiler.twit" target="_blank"
                      ><i class="fa fa-twitter"></i
                    ></a>
                  </li>
                  <li>
                    <a :href="this.Belediyebilgiler.insta" target="_blank"
                      ><i class="fa fa-instagram"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xl-8 margin-xlt-80">
              <div class="row">
                <div class="col-lg-4 d-flex justify-content-center">
                  <div class="footer-widget widget widget_nav_menu">
                    <h4 class="widget-title">Başkan</h4>
                    <ul>
                      <li>
                        <router-link to="/ozgecmis">
                          <i
                            class="fa fa-long-arrow-right"
                            aria-hidden="true"
                          ></i
                          >Başkan Özgeçmiş</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/mesaj">
                          <i
                            class="fa fa-long-arrow-right"
                            aria-hidden="true"
                          ></i
                          >Başkan'dan Mesaj</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/baskangaleri">
                          <i
                            class="fa fa-long-arrow-right"
                            aria-hidden="true"
                          ></i
                          >Başkan Albüm</router-link
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-4 d-flex justify-content-center">
                  <div class="footer-widget widget widget_nav_menu">
                    <h4 class="widget-title">Kurumsal</h4>
                    <ul>
                      <li>
                        <router-link to="/birimler">
                          <i
                            class="fa fa-long-arrow-right"
                            aria-hidden="true"
                          ></i
                          >Birimler</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/meclisuyeleri">
                          <i
                            class="fa fa-long-arrow-right"
                            aria-hidden="true"
                          ></i
                          >Meclis Üyeleri</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/muhtarlar">
                          <i
                            class="fa fa-long-arrow-right"
                            aria-hidden="true"
                          ></i
                          >Muhtarlarımız</router-link
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-4 d-flex justify-content-center">
                  <div class="footer-widget widget widget_nav_menu">
                    <h4 class="widget-title">
                      {{ Belediyebilgiler.belediyeadi }}
                    </h4>
                    <ul>
                      <li>
                        <router-link to="/kentrehberi">
                          <i
                            class="fa fa-long-arrow-right"
                            aria-hidden="true"
                          ></i
                          >Kent Rehberi</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/vefat">
                          <i
                            class="fa fa-long-arrow-right"
                            aria-hidden="true"
                          ></i
                          >Vefat Edenler</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/galeri">
                          <i
                            class="fa fa-long-arrow-right"
                            aria-hidden="true"
                          ></i
                          >Galeri</router-link
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-xl-11 offset-xl-1">
                  <div class="copyright-area-inner copyright-sm margin-top-35">
                    Copyrights
                    <a href="#"
                      >&copy; 2021
                      {{ Belediyebilgiler.belediyeadi }} Belediyesi.</a
                    >
                    Tüm Hakları Saklıdır.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- footer area end -->
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {
      Belediyebilgiler: {},
      domAd: "",
    };
  },
  async created() {
    const domainAd = window.location.hostname;
    this.domAd = domainAd;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Genel");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
  },
};
</script>
