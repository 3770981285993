<template>
  <div>
    <div v-if="!loadkont" class="preloader">
      <div class="icon"></div>
    </div>

    <!-- breadcrumb-area start -->
    <div class="breadcrumb-area breadcrumb-style-02 main-bg">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breadcrumb-inner padding-top-240">
              <h1 class="page-title">Duyurular</h1>
              <ul class="page-list">
                <li><a>Anasayfa /</a></li>
                <li><a>Duyurular</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="breadcrumb-icon">
        <i class="flaticon-fireworks"></i>
      </div>
    </div>
    <!-- upcoming area -->
    <div class="upcoming-area container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-12"></div>
        </div>
        <div class="row padding-top-120">
          <div
            class="col-lg-6"
            v-for="(duyuruilan, index) in Duyurular.slice(0, 10)"
            :key="index"
          >
            <div class="upcoming-box">
              <div class="ub-image">
                <a
                  @click="detayagit(duyuruilan.duyruilanId)"
                  style="cursor: pointer"
                  ><img :src="duyuruilan.kapak"
                /></a>
              </div>
              <div class="date-box">
                <span class="date">17</span>
                <span class="month">Dec</span>
              </div>
              <div class="ub-content">
                <h4
                  @click="detayagit(duyuruilan.duyruilanId)"
                  style="cursor: pointer"
                >
                  {{ duyuruilan.duyruilanAdi }}
                </h4>
                <p v-html="duyuruilan.duyruilanAciklamasi.slice(0, 200)"></p>
                <div class="btn-wrapper">
                  <a
                    class="btn btn-custom-primary"
                    data-toggle="modal"
                    data-target="#eventmodal"
                    @click="detayagit(duyuruilan.duyruilanId)"
                    >Görüntüle</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import firebase from "firebase/app";
export default {
  name: "Duyurular",
  data() {
    return {
      loadkont: false,
      Duyurular: [],
    };
  },

  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "DuyuruIlanlar");
    const duyurulist = this.$store.getters.getDuyuruIlanlar;
    this.Duyurular = Object.values(duyurulist);
    this.Duyurular.sort(function (b, a) {
      return a.duyruilanId - b.duyruilanId;
    });
    this.loadkont = true;
   // console.log(this.Duyurular);
  },

  methods: {
    async loadmore() {
      const son = this.Duyurular[this.Duyurular.length - 1];
      //const ID = son.duyuruilanId;
      //console.log(ID);
      await firebase
        .firestore()
        .collection("WebDuyuruIlan")
        .orderBy("giristarih", "desc")
        .startAt(son)
        .limit(6)
        .get()
        .then((result) => {
          result.forEach((doc) => {
            const duyuruilan = doc.data();
          // console.log("2" + duyuruilan.duyuruilanId);
            this.Duyurular.push(duyuruilan);
          });
        })
        .catch((err) => {
          console.log("duyurufazlahata" + err);
        });
    },
    detayagit(ID) {
      this.$router.push("/duyurudetay/" + ID);
    },
  },
};
</script>
<style>
.loadclass {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-top: 20px;
  min-width: 180px;
  text-transform: capitalize;
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 700;
  padding: 18px 30px 12px;
  background: var(--anacolor);
  border-radius: 0px;
  border-radius: 0px;
  overflow: hidden;
  font-family: "Ubuntu", sans-serif;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  align-items: center;
}
.loadclass:hover {
  background: #222222;
  color: #ffffff;
}
</style>
