<template>
  <div>
    <!--sidebar menu start-->
    <div class="sidebar-menu" id="sidebar-menu">
      <button class="sidebar-menu-close"><i class="flaticon-close"></i></button>
      <div class="sidebar-inner">
        <div class="sidebar-logo">
          <router-link to="/">
            <img :src="Belediyebilgiler.kapak" alt="logo" width="100px" />
          </router-link>
        </div>
        <div class="sidemenu-text">
          <p>{{ Belediyebilgiler.belediyeadi }} Belediyesi Resmi Web Sitesi</p>
        </div>
        <div class="sidebar-contact">
          <h4>Bizimle İletişime Geçin</h4>
          <ul>
            <li>
              <i class="fa fa-map-marker"></i
              >{{ Belediyebilgiler.belediyeadresi }}
            </li>
            <li><i class="fa fa-envelope"></i>{{ Belediyebilgiler.mail }}</li>
            <li><i class="fa fa-phone"></i>{{ Belediyebilgiler.iletisim }}</li>
          </ul>
        </div>
        <div class="sidebar-subscribe">
          <input type="text" placeholder="Email" />
          <button><i class="fa fa-long-arrow-right"></i></button>
        </div>
        <div class="social-link">
          <ul>
            <li>
              <a :href="this.Belediyebilgiler.face" target="_blank"
                ><i class="fa fa-facebook-f"></i
              ></a>
            </li>
            <li>
              <a :href="this.Belediyebilgiler.twit" target="_blank"
                ><i class="fa fa-twitter"></i
              ></a>
            </li>
            <li>
              <a :href="this.Belediyebilgiler.insta" target="_blank"
                ><i class="fa fa-instagram"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--sidebar menu end-->
    <!-- navbar start -->
    <div class="politix-navbar">
      <nav class="navbar navbar-area navbar-expand-lg nav-style-02">
        <div class="container nav-container">
          <div class="responsive-mobile-menu">
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#billatrail_main_menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
              <span class="bar1"></span>
              <span class="bar2"></span>
              <span class="bar3"></span>
            </button>
          </div>
          <div class="logo">
            <router-link to="/">
              <img :src="Belediyebilgiler.kapak" alt="" width="100px" />
            </router-link>
          </div>
          <div class="collapse navbar-collapse" id="billatrail_main_menu">
            <ul class="navbar-nav menu-open">
              <li><router-link to="/">Anasayfa</router-link></li>
              <li class="menu-item-has-children current-menu-item">
                <router-link to="/ozgecmis"> Başkanımız</router-link>
                <ul class="sub-menu">
                  <li>
                    <router-link to="/ozgecmis">
                      <i class="fa fa-long-arrow-right"></i>Başkan
                      Özgeçmiş</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/eskibaskanlar">
                      <i class="fa fa-long-arrow-right"></i>Belediye
                      Başkanlarımız
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/mesaj"
                      ><i class="fa fa-long-arrow-right"></i>Başkan
                      Mesaj</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/baskangaleri"
                      ><i class="fa fa-long-arrow-right"></i>Başkan
                      Galeri</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/iletisim">
                      <i class="fa fa-long-arrow-right"></i>Başkan'a
                      Sor</router-link
                    >
                  </li>
                </ul>
              </li>
              <li class="menu-item-has-children">
                <a href="#">Hizmetlerimiz</a>
                <ul class="sub-menu">
                  <li>
                    <a :href="Belediyebilgiler.ebelediye">
                      <i class="fa fa-long-arrow-right"></i>E-Belediye</a
                    >
                  </li>
                  <li v-if="domAd != 'eymir.zeplinx.com'">
                    <a :href="Belediyebilgiler.nobetciecz"
                      ><i class="fa fa-long-arrow-right"></i>Nöbetçi
                      Eczaneler</a
                    >
                  </li>
                  <li>
                    <router-link to="/belgeler"
                      ><i class="fa fa-long-arrow-right"></i>
                      Belge Örnekleri
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/vefat"
                      ><i class="fa fa-long-arrow-right"></i> Vefat Edenler
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/evlenenler"
                      ><i class="fa fa-long-arrow-right"></i>
                      Nikah Duyuruları
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="menu-item-has-children">
                <router-link to="/birimler"> Kurumsal</router-link>
                <ul class="sub-menu">
                  <li>
                    <router-link to="/birimler">
                      <i class="fa fa-long-arrow-right"></i
                      >Birimler</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/meclisuyeleri">
                      <i class="fa fa-long-arrow-right"></i>Meclis
                      Üyeleri</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/muhtarlar">
                      <i class="fa fa-long-arrow-right"></i
                      >Muhtarlarımız</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/encumenlarimiz"
                      ><i class="fa fa-long-arrow-right"></i>
                      Encümenlerimiz
                    </router-link>
                  </li>
                  <li v-if="domAd != 'eymir.zeplinx.com'">
                    <router-link to="/kararlar"
                      ><i class="fa fa-long-arrow-right"></i> Meclis Kararları
                    </router-link>
                  </li>
                  <li v-if="domAd != 'eymir.zeplinx.com'">
                    <router-link to="/raporlar"
                      ><i class="fa fa-long-arrow-right"></i> Faaliyet Raporları
                    </router-link>
                  </li>
                  <li v-if="domAd != 'eymir.zeplinx.com'">
                    <router-link to="/meclisgundemi"
                      ><i class="fa fa-long-arrow-right"></i> Meclis Gündemleri
                    </router-link>
                  </li>
                  <li v-if="domAd == 'www.nurhak.bel.tr'">
                    <router-link to="/etikkomisyonu"
                      ><i class="fa fa-long-arrow-right"></i>Etik Komisyonu
                    </router-link>
                  </li>
                  <li v-if="domAd == 'www.nurhak.bel.tr'">
                    <router-link to="/kvkk"
                      ><i class="fa fa-long-arrow-right"></i> KVKK Aydınlatma
                      Metni
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="menu-item-has-children">
                <router-link to="/haberler"> Güncel</router-link>
                <ul class="sub-menu">
                  <li>
                    <router-link to="/haberler">
                      <i class="fa fa-long-arrow-right"></i>Haberler
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/projeler">
                      <i class="fa fa-long-arrow-right"></i>
                      Projeler
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/duyuruilanlar"
                      ><i class="fa fa-long-arrow-right"></i>Duyurular
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="menu-item-has-children">
                <router-link to="/tarihce">
                  {{ Belediyebilgiler.belediyeadi }}</router-link
                >
                <ul class="sub-menu">
                  <li>
                    <router-link to="/tarihce">
                      <i class="fa fa-long-arrow-right"></i>Tarihce
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/sehitlerimiz">
                      <i class="fa fa-long-arrow-right"></i>
                      Şehitlerimiz
                    </router-link>
                  </li>
                  <li v-if="domAd != 'eymir.zeplinx.com'">
                    <router-link to="/kentrehberi"
                      ><i class="fa fa-long-arrow-right"></i>Kent Rehberi
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="nav-right-part">
            <ul>
              <li class="menubar d-none d-lg-block" id="navigation-button">
                <a><i class="flaticon-menu-button"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <!-- navbar end -->
  </div>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      Belediyebilgiler: {},
      domAd: "",
    };
  },
  async created() {
    const domainAd = window.location.hostname;
    this.domAd = domainAd;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Genel");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
  },
};
</script>
