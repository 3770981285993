<template>
  <div>
    <div v-if="!loadkont" class="preloader">
      <div class="icon"></div>
    </div>
    <!-- breadcrumb-area start -->
    <div class="breadcrumb-area breadcrumb-style-02 main-bg">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breadcrumb-inner padding-top-238">
              <h1 class="page-title">Haberler</h1>
              <ul class="page-list">
                <li><a>Anasayfa /</a></li>
                <li><a>Haberler</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="breadcrumb-icon">
        <i class="flaticon-fireworks"></i>
      </div>
    </div>
    <!-- breadcrumb-area end -->
    <!-- blog area start -->
    <div class="blog-area margin-top-120 margin-bottom-120 container-fluid">
      <div class="container-fluid">
        <div class="row">
          <div
            class="col-lg-12 col-md-12 col-sm-12"
            v-if="Haberlist.length > 0"
          >
            <div class="row">
              <div
                class="col-md-4"
                v-for="(haber, index) in Haberlist"
                :key="index"
              >
                <div class="blog-item-style-01 margin-bottom-30">
                  <div class="b-img">
                    <a @click="detayagit(haber.haberId)"
                      ><img :src="haber.kapak" alt=""
                    /></a>
                    <div class="blog-date-box">
                      <h4>
                        {{ haber.haberTarihi.split(" ")[0] }}<br /><span>{{
                          haber.haberTarihi.split(" ")[1].slice(0, -1)
                        }}</span>
                      </h4>
                    </div>
                  </div>
                  <div class="b-content">
                    <!-- <div class="blog-meta flex-column flex-lg-row">
                      <span class="causes-tag mr-3"
                        ><a href="#">Paylaş</a></span
                      >
                      <ul>
                        <li><span></span></li>
                        <li>
                          <a><i class="fa fa-facebook-f"></i></a>
                        </li>
                        <li>
                          <a><i class="fa fa-twitter"></i></a>
                        </li>
                        <li>
                          <a><i class="fa fa-instagram"></i></a>
                        </li>
                        <li>
                          <a><i class="fa fa-share-alt"></i></a>
                        </li>
                      </ul>
                    </div> -->
                    <h4 class="section-title">
                      <a @click="detayagit(haber.haberId)">{{
                        haber.haberAdi
                      }}</a>
                    </h4>
                    <p
                      v-html="haber.haberAciklamasi.slice(0, 200)"
                      style="overflow: hidden"
                    ></p>
                    <div class="btn-wrapper">
                      <a @click="detayagit(haber.haberId)">Görüntüle</a> ➝
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <Sidebar /> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import Sidebar from "@/views/Sidebar.vue";
export default {
  name: "Haberler",
  data() {
    return {
      loadkont: false,
      Haberler: {},
      Haberlist: [],
    };
  },
  components: {
    // Sidebar,
  },

  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Haberler");
    this.Haberler = this.$store.getters.getHaberler;
    this.Haberlist = Object.values(this.Haberler);
    this.Haberlist.sort(function (b, a) {
      return a.haberId - b.haberId;
    });
    this.loadkont = true;
    //console.log(this.Haberlist);
  },

  methods: {
    async loadmore() {
      alert("daha fazla");
    },
    detayagit(ID) {
      this.$router.push("/haberdetay/" + ID);
    },
  },
};
</script>
<style>
.loadclass {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-top: 20px;
  min-width: 180px;
  text-transform: capitalize;
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 700;
  padding: 18px 30px 12px;
  background: var(--anacolor);
  border-radius: 0px;
  border-radius: 0px;
  overflow: hidden;
  font-family: "Ubuntu", sans-serif;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  align-items: center;
}
.loadclass:hover {
  background: #222222;
  color: #ffffff;
}
</style>
