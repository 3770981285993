<template>
  <div>
    <div v-if="!loadkont" class="preloader">
      <div class="icon"></div>
    </div>

    <!-- breadcrumb-area start -->
    <div class="breadcrumb-area breadcrumb-style-02 main-bg">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breadcrumb-inner padding-top-240">
              <h1 class="page-title">Projeler</h1>
              <ul class="page-list">
                <li><a>Anasayfa /</a></li>
                <li><a>Projeler</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="breadcrumb-icon">
        <i class="flaticon-fireworks"></i>
      </div>
    </div>
    <!-- breadcrumb-area end -->

    <!-- service start -->
    <div class="our-service-area margin-top-120 margin-bottom-120">
      <div class="container">
        <div class="row">
          <div
            class="col-lg-4 col-md-6"
            v-for="(proje, index) in projelist"
            :key="index"
          >
            <div class="icon-box-style-05 margin-bottom-30">
              <div class="causes-img">
                <a @click="detayagit(proje.projeId)"
                  ><img
                    style="height: 200px; object-fit: cover; cursor: pointer"
                    :src="proje.kapak"
                    alt=""
                /></a>
                <span class="c-tag">{{ proje.projeDurum }} </span>
              </div>
              <div class="sb-content">
                <h4>
                  <a
                    @click="detayagit(proje.projeId)"
                    style="cursor: pointer"
                    >{{ proje.projeAdi }}</a
                  >
                </h4>
                <div class="needMore">
                  <p>
                    {{ proje.projeTarihi.split(" ")[0] }}
                    {{ proje.projeTarihi.split(" ")[1] }}
                    {{ proje.projeTarihi.split(" ")[2] }}
                  </p>
                </div>
                <div class="d-flex margin-top-10">
                  <a
                    class="seeAll"
                    @click="detayagit(proje.projeId)"
                    style="cursor: pointer"
                  >
                    Görüntüle</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-md-12">
            <div
              class="
                pagination-area
                d-flex
                justify-content-center
                margin-top-20
              "
            >
              <ul>
                <li>
                  <span class="page-bumber current">01</span>
                </li>
                <li>
                  <span class="page-bumber">02</span>
                </li>
                <li>
                  <span class="page-bumber">03</span>
                </li>
                <li>
                  <span class="next page-bumber">
                    <i class="fa fa-angle-right"></i
                  ></span>
                </li>
              </ul>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <!-- service end -->
  </div>
</template>
<script>
import firebase from "firebase/app";
export default {
  name: "projeler",
  data() {
    return {
      loadkont: false,
      projeler: {},
      projelist: [],
    };
  },

  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Projeler");
    this.projeler = this.$store.getters.getProjeler;
    this.projelist = Object.values(this.projeler);
    this.projelist.forEach((el) => {
      el.projeDurum = this.projectStatus(el.projeDurum);
    });
    this.projelist.sort(function (b, a) {
      return a.projeId - b.projeId;
    });
    this.loadkont = true;
    //console.log(this.projelist);
  },

  methods: {
    async loadmore() {
      const son = this.projeler[this.projeler.length - 1];
      //const ID = son.projeId;
      //console.log(ID);
      await firebase
        .firestore()
        .collection("WebProjeler")
        .orderBy("projetarih", "desc")
        .startAfter(son.projeId)
        .limit(6)
        .get()
        .then((result) => {
          result.forEach((doc) => {
            const proje = doc.data();
            this.projeler.push(proje);
          });
        })
        .catch((err) => {
          console.log("projefazlahata" + err);
        });
    },
    detayagit(ID) {
      this.$router.push("/projedetay/" + ID);
    },
    projectStatus(status) {
      switch (status) {
        case 1:
          return "Planlanan";
        case 2:
          return "Devam Ediyor";
        case 3:
          return "Tamamlandı";
        default:
          break;
      }
    },
  },
};
</script>
<style>
.loadclass {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-top: 20px;
  min-width: 180px;
  text-transform: capitalize;
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 700;
  padding: 18px 30px 12px;
  background: var(--anacolor);
  border-radius: 0px;
  border-radius: 0px;
  overflow: hidden;
  font-family: "Ubuntu", sans-serif;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  align-items: center;
}
.loadclass:hover {
  background: #222222;
  color: #ffffff;
}
</style>
