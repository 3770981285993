<template>
  <div>
    <!-- breadcrumb-area start -->
    <div class="breadcrumb-area breadcrumb-style-02 main-bg">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breadcrumb-inner padding-top-240">
              <h1 class="page-title">İletişim</h1>
              <ul class="page-list">
                <li><a href="index-2.html">Anasayfa /</a></li>
                <li><a href="contact.html">İletişim</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="breadcrumb-icon">
        <i class="flaticon-fireworks"></i>
      </div>
    </div>
    <!-- breadcrumb-area end -->
    <div class="contact-info-area margin-top-120 margin-bottom-90">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="icon-box-style-04 text-center margin-bottom-30">
              <div class="sb-icon">
                <a href="#"><i class="flaticon-phone"></i></a>
              </div>
              <div class="sb-content">
                <h4 class="title">Bizi Arayın</h4>
                <span>{{ Belediyebilgiler.iletisim }}</span
                ><br />
                <span>{{ Belediyebilgiler.faxnumarasi }}</span>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="icon-box-style-04 text-center margin-bottom-30">
              <div class="sb-icon">
                <a href="#"><i class="flaticon-sent-mail"></i></a>
              </div>
              <div class="sb-content">
                <h4 class="title">Email adresimiz</h4>
                <span>{{ Belediyebilgiler.mail }}</span
                ><br />
                <span>Help line: example@politics.com</span>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="icon-box-style-04 text-center margin-bottom-30">
              <div class="sb-icon">
                <a href="#"><i class="flaticon-place"></i></a>
              </div>
              <div class="sb-content">
                <h4 class="title">Bizi Ziyaret Edin</h4>
                <span>{{ Belediyebilgiler.belediyeadresi }} </span><br />
                <span>North America</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mapouter">
      <div class="gmap_canvas">
        <iframe
          width="100%"
          height="100%"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=university%20of%20san%20francisco&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        ></iframe>
        <a
          href="https://www.embedgooglemap.net/blog/elementor-pro-discount-code-review/"
          >elementor review</a
        >
      </div>
    </div>

    <div class="politx-content-area padding-top-70 margin-bottom-150">
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <form class="contact-form" @submit.prevent="yolla">
              <div class="form-group">
                <input
                  v-model="adii"
                  type="text"
                  name="username"
                  class="form-control"
                  id="name"
                  placeholder="Ad Soyad"
                  value
                />
              </div>
              <div class="form-group">
                <input
                  v-model="gidecekmail"
                  type="email"
                  ame="email"
                  class="form-control"
                  id="number"
                  placeholder="Eposta"
                  value
                />
              </div>
              <div class="form-group">
                <input
                  v-model="gidecekPhone"
                  type="phone"
                  name="phone"
                  class="form-control"
                  id="number"
                  placeholder="Telefon Numaranız"
                />
              </div>
              <div class="form-group">
                <textarea
                  class="form-control"
                  name="msg"
                  id="msg"
                  cols="30"
                  rows="5"
                  placeholder="Mesajınız..."
                ></textarea>
              </div>
              <div v-if="mailKontrol" class="alert alert-success" role="alert">
                Mesajınız gönderildi. Teşekkür Ederiz!
              </div>
              <div class="btn-wrapper padding-top-33">
                <button
                  v-if="!mailKontrol"
                  class="btn btn-custom-primary btn-block"
                >
                  Gönder
                </button>
              </div>
            </form>
          </div>
          <div class="col-lg-6 offset-lg-1 d-flex align-self-center">
            <div class="content-box-style-01 margin-top-45">
              <p class="section-subtitle">Bizimle İletişme Geçin</p>
              <h2 class="title">Sizin İçin Buradayız</h2>
              <p class="para">
                
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

 
  </div>
</template>
<script>
import firebase from "firebase/app";
export default {
  name: "Iletisim",
  data() {
    return {
      adii: "",
      gidecekmail: "",
      gidecekPhone: "",
      today: null,
      mesaj: "",
      Belediyebilgiler: {},
      mailKontrol: false,
      day: new Date().getDate().toString(),
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear().toString(),
    };
  },
  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Genel");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
  },
  methods: {
    async yolla() {
      const yeniIDD = Date.now().toString();
      await firebase
        .firestore()
        .collection("Mailler")
        .doc(yeniIDD)
        .set({
          adi: this.adii,
          gidecekmail: this.gidecekmail,
          gidecekPhone: this.gidecekPhone,
          mesaj: this.mesaj,
          domainId: this.$store.getters.getDomainId,
          today: this.day + "." + this.month + "." + this.year,
        });
      this.mailKontrol = true;
    },
  },
};
</script>
